import BasePlugin from '../BasePlugin'

export default class LoadFilePersonnelIssues extends BasePlugin {
  async execute () {
    this.context.$loading()
    let me = this
    let address = `${this.context.$config.api}/files/2d36cac3-976a-46cf-ab1a-136aed6abd14.pptx`
    this.context.$http({
      method: 'get',
      url: address,
      responseType: 'arraybuffer'
    }).then((response2) => {
      this.openExportAttachment(response2.data, me, 'pptx', 'Анализ исполнения_Блок по кадровым вопросам 8 мес. 2020 г..pptx')
      this.context.$loading().close()
    })
  }
}
